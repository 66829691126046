import * as React from 'react'
import HeaderLinks from './HeaderLinks'

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath

  return (
    <div className="main-wrapper" data-is-root-path={isRootPath}>
      <HeaderLinks />
      <main>{children}</main>
      <footer className="main-footer">
        <span className="icp">
          <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备18043647号-1</a>
        </span>
        <span className="copyrights">
          © {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.com" target="_blank" rel="noopener noreferrer">Gatsby</a>
        </span>
      </footer>
    </div>
  )
}

export default Layout
