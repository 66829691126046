import React from 'react'

const HeaderLinks = () => (
  <ul className="header-links">
    <li>
      <a href="https://github.com/javoski" title="Github" target="_blank" rel="noopener noreferrer">
        <i className="icon icon-github"></i>
      </a>
    </li>
    <li>
      <a href="/rss.xml" title="RSS Feed" target="_blank">
        <i className="icon icon-feed"></i>
      </a>
    </li>
  </ul>
)

export default HeaderLinks
